body {
    padding: 0;
    margin: 0;
}

.small-image img {
    width: 50px;
}

.medium-image img {
    width: 80px;
}

.image-container {
    display: flex;
    flex-wrap: wrap;
}

.image-container a {
    margin-right: 5px;
    margin-bottom: 5px;
}

.RaTopToolbar-root-57 {
    justify-content: flex-start !important;
}

.label {
    display: table-cell;
    padding: 16px;
    font-size: 0.875rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}

.large_image img {
    width: 135px;

}

.changelog-show .MuiFormControl-root {
    width: 100%;
}
.changelog-show .MuiToolbar-root {
    justify-content: flex-start !important;
}

.environment {
    position: fixed;
    top: 31px;
    z-index: 99;
    background: green;
    right: -30px;
    color: #fff;
    padding: 2px 61px;
    transform: rotate(45deg);
}

.img > img{
    object-fit: cover;
    margin: 0;
    min-width: 100%;
    min-height: 100%;
}

.img{
    border-radius: 100px;
    max-width: 100px;
    min-width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: 2px solid #b1b1b1;

}

.env_stage--dev{
    background: #7d7d7d;
}

.env_stage--stage{
    background: #f6d90d;
}

.env_stage--preprod{
    background: #a07be9;
}
